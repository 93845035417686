import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

function toggleContact(isSmall){
  var tel = $('.menu-contact a'),
      number = '042-565-5251';
  if(isSmall) {
    tel.attr('href', 'tel:' + number );
  } else {
    tel.attr('href', "#");
  }
}

function setYears(){
  if($(".years").length < 1 ) {
    return false;
  }
  var current = new Date(),
      currentYear = current.getFullYear(),
      establishedAt = new Date("1967/03/01"),
      establishedYear = establishedAt.getFullYear();
  // var years = Math.ceil((current-establishedAt)/(365*60*60*24*1000));
  var years = currentYear - establishedYear + 1;
  $(".years").text(years);
}

function init(){
  var isSmall = ! Foundation.MediaQuery.atLeast('medium');
  // console.log(isSmall);
  toggleContact(isSmall);
  setYears();
}

$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize){
  init();
});

init();
